import React from 'react';
import { Link } from 'react-router-dom';
import {urlConstants} from '../../_constant/url_constant';

class Footer extends React.Component {
   render() {
      return (
        <footer className="site-footer">
          	<div className="top-footer">
              <div className="container">
                  	<div className="row">
                  		<div className="col-xl-10 offset-xl-1 offset-lg-1 col-lg-10  contact-us  contact-us">
                      		<ul>
	                            <li className="footer-logo">
	                              <img src="assets/images/footer-logo.png" alt="footer-logo"/>
	                             </li>
	                             <li>
	                              <p>Have a question/feedback ?</p>
	                              <a href="mailto:contactus@wishuponmystory.com">contactus@wishuponmystory.com</a>
	                            </li>
	                             <li>
	                              <a href={urlConstants.BACKEND_URL+"contact-us"} className="footer-btn">Contact Us</a>
	                             </li>
                      		</ul>
                  		</div>
                  	</div>
                  	<div className="copy-right">
                      	<p>Wish Upon My Story © 2019 All rights reserved  </p>
                  	</div>
                  	<div className="footer-links">
                      	<ul>
                          	<li><Link to="/terms-and-conditions">Terms & conditions</Link></li>
                          	<li><Link to="/privacy-policy">Privacy Policy</Link></li>
                      	</ul>
                  	</div>
                  	<div className="social-icon">
                      	<ul>
                          	<li><a href=""><i className="fa fa-facebook"></i></a></li>
                          	<li><a href=""><i className="fa fa-instagram"></i></a></li>
                          	<li><a href=""><i className="fa fa-pinterest"></i></a></li>
                      	</ul>
                  	</div>
              	</div>
          	</div>
      	</footer>
      );
   }
}

export default Footer;