import React from 'react';
import Header from './layouts/Header';
import { Link } from 'react-router-dom';
import Footer from './layouts/Footer';
import $ from "jquery";
import { connect } from 'react-redux';
import {Action} from '../_action/action';
import { history } from '../_helpers/history';
import {urlConstants} from '../_constant/url_constant';
class BookDetails extends React.Component {
	constructor(props) {
       super(props);
       this.state = {
       	child_type: '',
       	child_img:'',
       	mother_img:'',
       	father_img:'',
       	friend_img:'',
       	child_name: '',
       	mother_name: '',
       	father_name: '',
       	friend_name: '',
       	child_avtar_type: '',
       	mother_avtar_type: '',
       	father_avtar_type: '',
       	bookDiscription: '',
       	reviewsList: ''
       }
       this.handleSubmit = this.handleSubmit.bind(this);
       this.handleChange = this.handleChange.bind(this);
       this.avtarChange = this.avtarChange.bind(this);
   }

   	componentDidMount() {
   		this.props.parentsPost();
	   	var urlParams = new URLSearchParams(window.location.search);
		if(urlParams.get('child')){
			this.setState({child_type: urlParams.get('child')});
			if(urlParams.get('child') == 'girl'){
				this.setState({friend_img: '25FB38CA-07AC-442C-9CC7-9E3CDE86C0FC.png'});
			}else{
				this.setState({friend_img: '37D7C612-35EA-4F47-AD0E-132CFE2FE86F.png'});
				
			}
			this.props.getCategoryDetails(urlParams.get('child'));
		}else{
			this.props.history.push("/");
		}
	   	window.$(document).ready(function() {
	   		window.$('html, body').animate({
		    scrollTop: window.$("#scroll").offset().top
		}, 1000);
	     	window.$("#show_hide").click(function () {
	     	window.$("#toggle_tst").show()
		});
		window.$("#show_hide1").click(function () {
		    window.$("#toggle_tst1").show()
		});
		window.$("#show_hide2").click(function () {
		    window.$("#toggle_tst2").show()
		});
		window.$("#show_hide3").click(function () {
		    window.$("#toggle_tst3").show()
			});
		});
		document.documentElement.setAttribute("lang", "en");
		document.documentElement.removeAttribute("class");
	}

   	handleChange (e){
   		if(e.target.name == 'child_name'){
	   		if(e.target.value.length > 18){
	   		}else{
	   			this.setState({[e.target.name]: e.target.value});
	   		}
	   	}else{
	   		if(e.target.value.length < 15){
	   			this.setState({[e.target.name]: e.target.value});
	   		}
	   	}
   	}
   	avtarChange(){

   	}
   	handleSubmit(event) {
    	event.preventDefault();
   		if(!this.state.child_img){
   			alert("Please select child avtar!");
   		}else if(!this.state.mother_img){
   			console.log(this.state.mother_img)
   			alert("Please select mother avtar!");
   		}else if(!this.state.father_img){
   			alert("Please select father avtar!");
   		}else if(!this.state.friend_img){
   			alert("Please select friend avtar!");
   		}else{
   			var data = this.state;
	    	console.log(data);
	    	window.location=urlConstants.BACKEND_URL+'/product/'+this.state.child_type+'-book-'+data.child_avtar_type+'-'+data.mother_avtar_type+'-'+data.father_avtar_type+'?c_n='+encodeURIComponent(data.child_name)+'&c_i='+data.child_img+'&m_n='+data.mother_name+'&m_i='+data.mother_img+'&f_n='+data.father_name+'&f_i='+data.father_img+'&fr_n='+encodeURIComponent(data.friend_name)+'&fr_i='+data.friend_img+'&child_type='+this.state.child_type+'&av_type='+data.child_avtar_type;
   		}
  	}
  	componentWillReceiveProps(nextProps){
  		if(nextProps.items){
  			var data = nextProps.items;
  			if(data[0].term_id){
  				var value = JSON.stringify( data[0].description);
  				value = value.replace(/\\n/g, "<br>");
  				value = value.replace(/\\r/g, "");
  				var description = value.substring(1, value.length-1);
  				$(".bookDiscription").html(description);
  				this.setState({bookDiscription: data[0].description});
  			}
  			if(data[0].post_author){
				var reviews = nextProps.items.map((review)=>{
					return  <div className="card-body">
		            	        <div className="row">
		                    	    <div className="col-md-2">
		                    	        <img src="https://image.ibb.co/jw55Ex/def_face.jpg" className="img img-rounded img-fluid"/>
		                    	        <p className="text-secondary text-center"></p>
		                    	    </div>
		                    	    <div className="col-md-10">
		                    	        <p><strong>{review.post_title}</strong>
		                    	        	<span className="float-right"><i className="text-warning fa fa-star"></i></span>
					                        <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            <span className="float-right"><i className="text-warning fa fa-star"></i></span>
					        	            
		                    	        </p>
		                    	        <p>{review.post_content}</p>
		                    	    </div>
		            	        </div>
	            	    </div>
		        });
		        this.setState({reviewsList: reviews});
  			}
  		}
  	}
   	render() {
      return (
         <div>
            <Header/>
            	<div className="thumbbook-deatils">
					{/* -Carousel Wrapper--*/}
					<div className="container">
						<div className="row">
							<div className="col-md-7">
								{this.state.child_type == 'girl' &&
									<div id="carousel-thumb" className="carousel slide carousel-fade carousel-thumbnails" data-ride="carousel">
										{/*--Slides--*/}
									    <div className="carousel-inner" role="listbox">
									        <div className="carousel-item active">
									            <img className="d-block w-100" src="assets/images/girl-slider-1.jpg"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/girl-slider-2.jpg" alt="Second slide"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/girl-slider-3.jpg" alt="Third slide"/>
									        </div>
											 <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/girl-slider-4.jpg" alt="Fourth slide"/>
									        </div>
									    </div>
									    {/*--/.Slides--*/}
									    {/*--Controls--*/}
									    <a className="carousel-control-prev" href="#carousel-thumb" role="button" data-slide="prev">
									        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
									        <span className="sr-only">Previous</span>
									       </a>
									    <a className="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
									        <span className="carousel-control-next-icon" aria-hidden="true"></span>
									        <span className="sr-only">Next</span>
									    </a>
									    {/*--/.Controls--*/}
									    <ol className="carousel-indicators">
									        <li data-target="#carousel-thumb" data-slide-to="0" className="active"> <img className="d-block w-100" src="assets/images/girl-slider-1.jpg"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="1"><img className="d-block w-100" src="assets/images/girl-slider-2.jpg" className="img-fluid"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="2"><img className="d-block w-100" src="assets/images/girl-slider-3.jpg" className="img-fluid"/></li>
											<li data-target="#carousel-thumb" data-slide-to="3"><img className="d-block w-100" src="assets/images/girl-slider-4.jpg" className="img-fluid"/></li>
									    </ol> 
									</div>
								}

								{this.state.child_type == 'boy' &&
									<div id="carousel-thumb" className="carousel slide carousel-fade carousel-thumbnails" data-ride="carousel">
										{/*--Slides--*/}
									    <div className="carousel-inner" role="listbox">
									        <div className="carousel-item active">
									            <img className="d-block w-100" src="assets/images/boy-slider-1.jpg" alt="Second slide"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/boy-slider-2.jpg"/>
									        </div>
									        <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/boy-slider-3.jpg" alt="Third slide"/>
									        </div>
											 <div className="carousel-item">
									            <img className="d-block w-100" src="assets/images/boy-slider-4.jpg" alt="Fourth slide"/>
									        </div>
									    </div>
									    {/*--/.Slides--*/}
									    {/*--Controls--*/}
									    <a className="carousel-control-prev" href="#carousel-thumb" role="button" data-slide="prev">
									        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
									        <span className="sr-only">Previous</span>
									       </a>
									    <a className="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
									        <span className="carousel-control-next-icon" aria-hidden="true"></span>
									        <span className="sr-only">Next</span>
									    </a>
									    {/*--/.Controls--*/}
									    <ol className="carousel-indicators">
									        <li data-target="#carousel-thumb" data-slide-to="0" className="active"><img className="d-block w-100" src="assets/images/boy-slider-1.jpg" className="img-fluid"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="1"> <img className="d-block w-100" src="assets/images/boy-slider-2.jpg"/></li>
									        <li data-target="#carousel-thumb" data-slide-to="2"><img className="d-block w-100" src="assets/images/boy-slider-3.jpg" className="img-fluid"/></li>
											<li data-target="#carousel-thumb" data-slide-to="3"><img className="d-block w-100" src="assets/images/boy-slider-4.jpg" className="img-fluid"/></li>
									    </ol> 
									</div>
								}
							</div>
							{/*--/.Carousel Wrapper--*/}     
								<div className="col-md-5 right-side-review">
									   	<h2>Perfect Eid Book {this.state.child_type}</h2>
									    <h3>$32.99 USD </h3>
									    <a href="#">A personalized Eid book</a>
								   	<div className="bottom-reviews">
								    	<a href="#" data-toggle="modal" data-target="#exampleModal"><img src="assets/images/star-ratning.png" alt="stars" />Read our reviews</a>
								     	<p>Bringing the most joyous Eid to your little Muslims. A beautifully illustrated and written story in charming verse that is sure to create a bonding experience for the whole family.</p>
										<p className="bookDiscription">
											
											{/*} <li>Recommended for ages: 0-8</li>
										     <li>Preview a SAMPLE book before buying</li>
										     <li> Print/Ships to Cnada, USA & International within 5 days.</li>*/}
											
										</p>
								   </div>
								</div>
						</div>
					</div>			
				</div>
				<div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
				  <div className="modal-dialog" role="document">
				    <div className="modal-content">
				      <div className="modal-header">
				        <h5 className="modal-title" id="exampleModalLabel">Customer Reviews</h5>
				        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
				          <span aria-hidden="true">&times;</span>
				        </button>
				      </div>
				      <div className="modal-body">
				      	{this.state.reviewsList &&
						    this.state.reviewsList
						}
				      </div>
				      <div class="modal-footer">
				        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
				      </div>
				    </div>
				  </div>
				</div>
				<div className="form-book">
				  	<form> 
				      	<div className="teal-input-head">
				       		<div className="container">
				       			<div className="row">
				        			<div className="col-lg-10 offset-lg-1">
					      				<h2>Create the child's entire book</h2>
					   	   				<div className="input-first">
					       					<label htmlFor="lname">Language</label>
								           <select>
								            <option> English US </option>
								            {/*<option> United kingdom</option>*/}
								           </select>
				           						<span></span>
						  				</div>
					    			</div>
					   			</div>
					  		</div>
					  	</div>
						<div className="container"  >
				  			<div className="row">
				     			<div className="col-lg-10 offset-lg-1 form-started ">
				     				<form onSubmit={this.handleSubmit}>
					       				<div className="form-side">	
							 				<div className="input-first">
					          					<label>Child Name</label>
					             				<input type="text" id="show_hide" name="child_name" className="flip" placeholder="Child Name" onChange={this.handleChange} autoComplete="off" onClick="myFunction()" value={this.state.child_name} maxLength="18" required/>
							  					<div className="select-lightbox"  id="toggle_tst">
							  						{this.state.child_type == "girl" &&
							  							<React.Fragment>
								  							<label className="container girl-first" >
													           <input type="radio" name="boy"  autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'785C94E7-63A4-459D-9CB9-0E5A402A12C1.png',child_avtar_type:'L'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container girl-second" > 
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'77D2B572-24C0-4E9E-84B6-F00A9E2003FC.png',child_avtar_type:'D'})} />
													           <span className="checkmark"></span>
												        	</label>
												        	<label className="container girl-third" >
													           <input type="radio" name="boy" autoComplete="off"  onChange={this.avtarChange} onClick={()=> this.setState({child_img:'D1FA953D-3FD1-4155-92EC-68915572C3EE.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
												        	</label>
												        </React.Fragment>
							  						}
							  						{this.state.child_type == "boy" &&
							  							<React.Fragment>
															<label className="container boy-first" >
													           <input type="radio" name="boy" autoComplete="off"  onClick={()=> this.setState({child_img:'9226FB2C-81B1-467B-BB2D-F476FA8F1E54.png',child_avtar_type:'L'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container boy-second" >
													           <input type="radio" name="boy" autoComplete="off"  onClick={()=> this.setState({child_img:'FCC15EF3-8A7D-435E-9AC2-F58AF0DB7280.png',child_avtar_type:'D'})} />
													           <span className="checkmark"></span>
													        </label>
													        <label className="container boy-third" >
													           <input type="radio" name="boy" autoComplete="off" onClick={()=> this.setState({child_img:'242D0940-CD5C-4440-A03D-3FB301E5EE8A.png',child_avtar_type:'B'})} />
													           <span className="checkmark"></span>
													        </label>
												        </React.Fragment>
												    }
							   					</div>
							    			</div>
											<div className="input-first">
											</div>
										</div>
					          			<div className="form-side" id="scroll">
								          	<div className="input-first">
										    	<input type="text" id="show_hide1" name="mother_name" maxLength="15" onChange={this.handleChange} placeholder="eg. Mummy" autoComplete="off" value={this.state.mother_name}  onClick="myFunction()" required/>
										    	<div className="select-lightbox" id="toggle_tst1">
										    		<React.Fragment>
												  		<label className="container mother-first" >
											           		<input type="radio" name="m_gender" autoComplete="off" onClick={()=> this.setState({mother_img: 'BABDC835-E4C1-467A-BA67-9CCEC0F63EB8.png',mother_avtar_type:'D'})} />
											           		<span className="checkmark"></span>
										          		</label>
										          		<label className="container mother-second" >
											           		<input type="radio" name="m_gender" autoComplete="off" onClick={()=> this.setState({mother_img: '1CD0B870-1702-40A8-84E5-81BDB2D2FF3A.png',mother_avtar_type:'B'})} />
											           		<span className="checkmark"></span>
										          		</label>
										          		<label className="container mother-third" >
											           		<input type="radio" name="m_gender" autoComplete="off" onClick={()=> this.setState({mother_img: 'DD6A5888-0CFE-4C53-A13D-1B415F674696.png',mother_avtar_type:'BH'})} />
											           		<span className="checkmark"></span>
										          		</label>
										          		<label className="container mother-fourth" >
											           		<input type="radio" name="m_gender" autoComplete="off" onClick={()=> this.setState({mother_img: 'F280B25A-EA0C-4D08-B82B-D0F8F95E2DA4.png',mother_avtar_type:'DH'})} />
											           		<span className="checkmark"></span>
										          		</label>
										          		<label className="container mother-fifth" >
											           		<input type="radio" name="m_gender" autoComplete="off" onClick={()=> this.setState({mother_img: 'F7CD07F9-01AA-4F34-8226-DEEE02123EB4.png',mother_avtar_type:'LH'})} />
											           		<span className="checkmark"></span>
										          		</label>
										          	</React.Fragment>
									          	</div>
										    </div>
										    <div className="input-first">
									        	<p>What {this.state.child_type == 'girl'? 'she' : 'he'} calls {this.state.child_type == 'girl'? 'her' : 'his'} mother</p>
								           	</div>
										</div>
							           	<div className="form-side" >
								           	<div className="input-first">		  
								            	<input type="text" id="show_hide2" name="father_name" maxLength="15" onChange={this.handleChange} autoComplete="off" placeholder="eg. Daddy" value={this.state.father_name} onClick="myFunction()" required/>
												<div className="select-lightbox" id="toggle_tst2">
													<React.Fragment>
														<label className="container daddy-second" >
									           				<input type="radio" name="f_gender" autoComplete="off" onClick={()=> this.setState({father_img:'Screenshot_3.jpg',father_avtar_type:'L'})}/>
									           				<span className="checkmark"></span>
								          				</label>
											  			<label className="container daddy-first" >
									           				<input type="radio" name="f_gender" autoComplete="off" onClick={()=> this.setState({father_img:'Screenshot_2.jpg',father_avtar_type:'D'})}/>
									           				<span className="checkmark"></span>
								          				</label>
								          				
								          				<label className="container daddy-third" >
									           				<input type="radio" name="f_gender" autoComplete="off" onClick={()=> this.setState({father_img:'Screenshot_1.jpg',father_avtar_type:'B'})} />
									           				<span className="checkmark"></span>
								          				</label>
								          			</React.Fragment>
									   			</div>
									   		</div>
										   	<div className="input-first">
									       		<p>What {this.state.child_type == 'girl'? 'she' : 'he'} calls {this.state.child_type == 'girl'? 'her' : 'his'} father</p>
								            </div>
										</div>
										<div className="form-side" >
										  	<div className="input-first">
								           		<input type="text" name="friend_name" maxLength="15" onChange={this.handleChange} autoComplete="off" placeholder="eg. Friend" value={this.state.friend_name} id="show_hide3" required/>
										   		<div className="select-lightbox" id="toggle_tst3">
										   			{this.state.child_type == "boy" &&
											  			<label className="container friends-first">
												           <input type="radio" name="F_gender" autoComplete="off" onClick={()=>this.setState({friend_img: '37D7C612-35EA-4F47-AD0E-132CFE2FE86F.png'})} checked/>
												           <span className="checkmark"></span>
												        </label>
												    }
												    {this.state.child_type == "girl" &&
												        <label className="container friends-second">
												           <input type="radio" name="F_gender" autoComplete="off" onClick={()=>this.setState({friend_img: '25FB38CA-07AC-442C-9CC7-9E3CDE86C0FC.png'})} checked/>
												           <span className="checkmark"></span>
												        </label>
												    }
										   		</div>
										  	</div>
										  	<div className="input-first">
								           		<p>A name of child’s friend</p>
										   	</div>
										</div>									  
								        <div className="bottom-submit">  
								          	<input type="submit" value="View Sample Book"/>
								           	<h5>*For single parent family email us at contactus<a href="#">@wishuponmystory.com</a></h5>
								        </div> 
								    </form>
				          		</div>
				          	</div>
				        </div>
				    </form> 
				</div>
            <Footer/>
         </div>
      );
   }
}
const mapStateToProps = (state) => {
    return {
	    items: state.items,
	};
};
const actionCreators = {
  bookContant: Action.bookContant,
  parentsPost: Action.parentsPost,
  getCategoryDetails: Action.getCategoryDetails,
}

export default connect(mapStateToProps, actionCreators)(BookDetails);