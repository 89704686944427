import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';
import $ from "jquery";
class Cart extends React.Component {
   render() {
      return (
         <div>
            <Header/>
            	{/*--  cart-center --*/}
			    <div className="add-book-cart">
				    <div className="container">
				      	<h2> Your Cart (2) </h2>
					    <div className="row">
						  	<div className="col-md-7">
						       	<div className="cart-content">
						        	<img src="assets/images/cart-1.png" alt="left-img"/>
									<div className="right-cont">
						          		<h3>Adam’s Perfect Eid<span> Remove <img src="assets/images/carty.png" /></span> </h3> 
						          		<h4>$29.99 USD</h4> 
						          		<a href="#"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>edit book </a>
						         	</div>
						        </div>
				               	<div className="cart-content">
						        	<img src="assets/images/cart-1.png" alt="left-img"/>
									<div className="right-cont">
								        <h3>Adam’s Perfect Eid<span> Remove <img src="assets/images/carty.png" /></span> </h3> 
								        <h4>$29.99 USD</h4> 
							          	<a href="#"><i className="fa fa-pencil-square-o" aria-hidden="true"></i>edit book </a>
							        </div>
						    	</div>
							</div>
							<div className="col-md-5">
							  	<div className="order-now">
							  		<h2> Order summary</h2>
							    	<h3> Subtotal (2 items) <span>$69.99 USD </span></h3>
							     	<div className="order-total">
							      	<h4>Order Total<span> $69.99 USD</span> </h4>
								    <div className="checkout">
								      	<a href="#">  Checkout</a>
								    </div>
									<a href="#"> <img src="assets/images/pay-pal.png" alt="pay-pal"/></a>
							    </div>
							</div>
								<div className="order-now">
								  <input type="email" name="email" value="Coupon Code"/>
								  <button type="submit">Apply</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/*--  cart-center  end--*/}
            <Footer/>
         </div>
      );
   }
}

export default Cart;