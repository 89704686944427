import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
// import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './cropImage'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
let dogImg = '';
  

const Demo = ({ image_uploaded, handleImageCropped, closeCropper ,lang }) => {
  // console.log(image_uploaded);
  const [image, setImage] = useState(image_uploaded);
  const [language, setLang] = useState(lang);
  const [cropData, setCropData] = useState(false);
  const [cropper, setCropper] = useState();
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(0)

  console.log('language',language)
 
    

   const getCropData = () => {

    if (typeof cropper !== "undefined") {
      console.log(cropper.getCroppedCanvas().toDataURL());
      handleImageCropped({croppedImage: cropper.getCroppedCanvas().toDataURL()})
    }
  };

  const handleRotate = (val)=>{
    console.log('rotation',rotation)
    if (typeof cropper !== "undefined") {
      setRotation(val);
     // cropper.rotate(rotation);
	  cropper.rotateTo(rotation);
    }
  }

  const handleZoom = (val)=>{
    if (typeof cropper !== "undefined") {
      setZoom(val)
      //cropper.zoom(val);
	  cropper.zoomTo(val);
    }
  }
  return (
   
    <div className="cropper-modal-2">

        <div className="cropper-content">
        <div className="close-click"><img className="close-click-img" onClick={closeCropper} src="assets/images/cross-img.png"/></div>
      
            <Cropper
              style={{ height: 400, width: "100%" }}
              aspectRatio={4 / 4}
              rotate={rotation}
              preview=".img-preview"
              src={image}
              viewMode={1}
              guides={true}
              zoomable={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {

                setCropper(instance);
              }}
              />
           
        
          <div className="cropper-controls" style={{'margin-top': '10px'}} >
            <div className="col-sm-2" style={{'float':'left'}}>
                  <Typography
                    variant="overline"
                 
                  >
                  Zoom
                  </Typography>
              </div>
              <div className="col-sm-3" style={{'float':'left'}}>
                  <Slider
                    value={zoom}
                    min={0}
                    max={3}
                    step={.1}
                    aria-labelledby="Zoom"
                  
                    onChange={(e, zoom) =>handleZoom(zoom)}
                  />
              </div>
              <div className="col-sm-2" style={{'float':'left'}}>
                  <Typography
                    variant="overline"
                    
                  >
                    Rotation
                  </Typography>
               </div>
              <div className="col-sm-3" style={{'float':'left'}}>
                  <Slider
                    value={rotation}
                    min={0}
                    max={180}
                    step={10}
                    aria-labelledby="Rotation"
                   
                    onChange={(e, rotation) => handleRotate(rotation)}
                  />
               </div>
              <div className="col-sm-2 subbtn" styshowCroppedImagele={{'float':'left'}}>
       
                  {language == "english" &&
                  <Button
                    onClick={getCropData}
                    variant="contained"
                    color="primary"
                  >
                  Save
                  </Button>
                }
                 {language == "French" &&
                  <Button
                    onClick={getCropData}
                    variant="contained"
                    color="primary"
                  >
                  Sauvegarder
                  </Button>
                }
              </div>
          </div>
      </div>
    </div>
  )
}

export default Demo