import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';
import $ from "jquery";
import {Action} from '../_action/action';
import { connect } from 'react-redux';

class CustomerReview extends React.Component {
	constructor(props) {
       super(props);
       this.state = {
        	reviewsList: '',
       }
   }
	componentDidMount() {
       	this.props.parentsPost();
   		window.$('html, body' ).scrollTop( 0 );
   	}
   	componentWillReceiveProps(nextProps) {
	    if(nextProps.items){
	    	var reviews = nextProps.items.map((review)=>{
	          	return <div>  
	                <h5>{review.post_title} :</h5> 
	                <p>{review.post_content} </p>
	           	</div>
	        });
	        this.setState({reviewsList: reviews});
	    }
	}
   render() {
      return (
         <div>
            <Header/>
            	{/*--  cart-center --*/}
			    <div className="add-book-cart">
				    <div className="container">
				   	 	<React.Fragment>
					      	<h1>Our Reviews</h1><br/>
						    {this.state.reviewsList &&

						    	this.state.reviewsList
						    }
						</React.Fragment>
					</div>
				</div>
				{/*--  cart-center  end--*/}
            <Footer/>
         </div>
      );
   }
}
const mapStateToProps = (state) => {
   return {
     items: state.items,
   };
};
const actionCreators = {
  parentsPost: Action.parentsPost,
}

export default connect(mapStateToProps, actionCreators)(CustomerReview);