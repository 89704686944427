const initialState = {
   isLoading: false,
   items: [],
   response: [],
   constant: []
};
const reducer = (state = initialState, action) => {
   switch (action.type) {
      case 'ITEMS_REQUEST':
         return Object.assign({}, state, {
            isLoading: action.val
         })
      case 'ITEMS_REQUEST_SUCCESS':
         return Object.assign({}, state, { 
            isLoading: action.isLoading
         })
      //for get post
      case 'GET_PARENTS_POST':
         return Object.assign({}, state, { 
            isLoading: action.isLoading,
         })
      case 'GET_PARENTS_POST_SUCCESS':
         return Object.assign({}, state, { 
            isLoading: action.isLoading,
            items: action.items
         })
      //GET CONSTANT
       case 'GET_CONSTANT':
         return Object.assign({}, state, { 
            isLoading: action.isLoading,
         })
      case 'GET_CONSTANT_SUCCESS':
         return Object.assign({}, state, { 
            isLoading: action.isLoading,
            constant: action.items
         })
      //save book contant
      case 'SAVE_BOOK_CONTANT':
         return Object.assign({}, state, { 
            book_contant: action.data
         })
      case 'GET_PRODUCT_CATEGORY_DATAILS':
         return Object.assign({}, state, {
            isLoading: action.val
         })
      case 'GET_PRODUCT_CATEGORY_DATAILS':
         return Object.assign({}, state, {
            isLoading: action.val
         })
      case 'GET_PRODUCT_CATEGORY_DATAILS_SUCCESS':
         return Object.assign({}, state, { 
            isLoading: action.isLoading,
            items: action.response
         })

      default:
         return state;
   }
}
export default reducer;