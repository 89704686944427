import { appConstant } from '../_constant/app.constant';
import { urlConstants } from '../_constant/url_constant';
import axios from 'axios';
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";

const WooCommerce = new WooCommerceRestApi({
  url: urlConstants.BACKEND_URL,
  consumerKey: 'ck_4ad9ba067a0df68518e37d2f8f56eb73a2a7cafd',
  consumerSecret: 'cs_6b2f93fa4d8228b2951a01f6dbab5d378afd8b55',
  version: 'wc/v3'
});

export const Action = {
   itemsRequest,
   parentsPost,
   createProduct,
   bookContant,
   addToCart,
   getCategoryDetails,
   GetConstant
}

//get post for home page(WHAT PARETS POST); 
function parentsPost(){
   return dispatch => {
      dispatch(loading());

      axios.get(urlConstants.BACKEND_URL+'/wp-json/sections/v1234/get_post')
      .then((response) => {
        dispatch(success(response.data))
      })

   }

   function loading(){
      return {
         type: appConstant.GET_PARENTS_POST,
         isLoading: true
      }
   }

   function success(data){
      return {
         type: appConstant.GET_PARENTS_POST_SUCCESS,
         items: data
      }
   }

}
//end get post 

//GetConstant 
function GetConstant() {
   return dispatch => {
      dispatch(loading());

      axios.get(urlConstants.BACKEND_URL+'/wp-json/sections/v1234/GetConstant')
      .then((response) => {
        dispatch(success(response.data))
      })

   }
   function loading(){
      return {
         type: appConstant.GET_CONSTANT,
         isLoading: true
      }
   }

   function success(data){
      return {
         type: appConstant.GET_CONSTANT_SUCCESS,
         items: data
      }
   }
}
//GetConstant end

//addToCart product

function addToCart(data){
  return dispatch => {
      dispatch(loading(data));
      console.log(data);
      axios.get('http://192.168.1.50/Wishuponmystory/wordpress/index.php/wp-json/sections/v1234/add_item_to_cart')
      .then((response) => {
        console.log(response);  
      })
   }

   function loading(){
      return {
         type: appConstant.ADD_TO_CART,
         isLoading: true
      }
   }
   function success(data){
      return {
         type: appConstant.ADD_TO_CART_SUCCESS,
         response: data
      }
   }
}

//create woocommerce product

function createProduct(){
   return dispatch => {
      dispatch(loading());
      const data = {
        name: "Premium Quality",
        type: "simple",
        regular_price: "21.99",
        description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.",
        short_description: "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
        categories: [
          {
            id: 9
          },
          {
            id: 14
          }
        ],
        images: [
          {
            src: ""
          },
          {
            src: "http://demo.woothemes.com/woocommerce/wp-content/uploads/sites/56/2013/06/T_2_back.jpg"
          }
        ]
      };

    WooCommerce.post("products", data)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error.response.data);
      });

   }
  
   function loading(){
      return {
         type: appConstant.CREAT_PRODUCT,
         isLoading: true
      }
   }
}
//saveing book contant
function bookContant(data){
   return dispatch =>{
      dispatch(save(data));
   }

   function save(data){
      return{
         type: appConstant.SAVE_BOOK_CONTANT,
         data
      }
   }
}

//for test 
function itemsRequest() {
  
      axios.get(urlConstants.WP_API_URL+'/get_post')
      .then((response) => {
      })
   return dispatch => {
      var val = true;
      dispatch(success(val));
   }
   function success(val){
      return{
         type: appConstant.ITEMS_REQUEST,
         val
      }  
   }
}

//end test

function getCategoryDetails(type){
    return dispatch => {
      dispatch(loading());
      axios.get(urlConstants.WP_API_URL+'/get_category_discription?category='+type)
      .then((response) => {
        console.log(response);
        dispatch(success(response.data));
      })
    }
     function loading(){
      return {
         type: appConstant.GET_PRODUCT_CATEGORY_DATAILS,
         isLoading: true
      }
   }
   function success(data){
      return {
         type: appConstant.GET_PRODUCT_CATEGORY_DATAILS_SUCCESS,
         response: data
      }
   }
}
