import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';


class AllBooks extends React.Component {
	componentDidMount() {
    
   	}
   render() {
      return (
         <div>
            <Header/>
            {/*-- banner inner --*/}
			 <section className="inner-banner">
			  <img src="assets/images/all-book.png" alt="banner-inner"/>
			 </section>
			{/*-- header end -*/}
			{/*-- books section  --*/}
			<section className="Our-book">
		        <div className="container">
		            <div className="row">
		                <div className="col-lg-4 offset-lg-2 col-md-6">
		                	<Link to="book-details?child=girl" style={{textDecoration: 'none'}}>
			                    <div className="build-a girl-book">
								   <h2>Personalized Perfect Eid Book </h2>
			                        <img src="assets/images/girl-1.png" alt="girl"/>
									<div className="bottom-con">
									  <h3> Build a girl book</h3>
									   <p>A festive personalized book that allows kids to explore islamic traditions from around the world in a fun and memorable way!</p>
									   <ul>
			                        <li> <Link to="#">Age 0-8</Link> </li>
			                        <li> <Link to="#">Starting from $32.99 USD</Link> </li>
									</ul>
			                      </div>
			                    </div>
			                </Link>
		                </div>
		                <div className="col-lg-4 col-md-6">
		                	<Link to="book-details?child=boy" style={{textDecoration: 'none'}}>
			                    <div className="build-a boy-book"> 
									<h2> Personalized Perfect Eid BooK</h2>
			                        <img src="assets/images/boy-1.png" alt="boy"/>
									<div className="bottom-con">
										  <h3> Build a boy book</h3>
										   <p>A festive personalized book that allows kids to explore islamic traditions from around the world in a fun and memorable way!</p>
										   <ul>
				                        <li> <Link to="#">Age 0-8</Link> </li>
				                        <li> <Link to="#">Starting from $32.99 USD</Link> </li>
										</ul>
			                    	</div>
		                    	</div>
		                    </Link>
		                </div>
		            </div>
		        </div>
		    </section>
			{/*-- books section end --*/}
			{/*---- Subhan allah book ---*/}
			<section className="Our-book" style={{'padding': '0px 0px 120px'}}>
		        <div className="container">
		            <div className="row">
		                <div className="col-lg-4 offset-lg-2 col-md-6">
		                	<Link to="Details" style={{textDecoration: 'none'}}>
			                    <div className="build-a boy-book subhanallah-boy-girl-book">
								   <h2>Personalized SubhanAllah, You Book</h2>
			                        <img src="assets/images/add-eid-book.png" alt="girl"/>
									<div className="bottom-con">
									  <h3> Build a boy/girl book</h3>
									   <p>Cozy up with your little one with this sweet love letter that celebrates Allah's creations through the joys and wonders of childhood.</p>
									   <ul>
			                        <li> <Link to="#">Age 0-8</Link> </li>
			                        <li> <Link to="#">Starting from $32.99 USD</Link> </li>
									</ul>
			                      </div>
			                    </div>
			                </Link>
		                </div>
		            </div>
		        </div>
		    </section>
            <Footer/>
         </div>
      );
   }
}

export default AllBooks;