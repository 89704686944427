export const  appConstant = {
	ITEMS_REQUEST : 'ITEMS_REQUEST',
	ITEMS_REQUEST_SUCCESS : 'ITEMS_REQUEST_SUCCESS',

	GET_PARENTS_POST: 'GET_PARENTS_POST',
	GET_PARENTS_POST_SUCCESS: 'GET_PARENTS_POST_SUCCESS',
	GET_PARENTS_POST_FALIUER: 'GET_PARENTS_POST_FALIUER',

	CREAT_PRODUCT: 'CREAT_PRODUCT',
	CREAT_PRODUCT_SUCCESS: 'CREAT_PRODUCT_SUCCESS',
	CREAT_PRODUCT_FALIUER: 'CREAT_PRODUCT_FALIUER',

	SAVE_BOOK_CONTANT: 'SAVE_BOOK_CONTANT',

	ADD_TO_CART: 'ADD_TO_CART',
	ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
	ADD_TO_CART_FALIUER: 'ADD_TO_CART_FALIUER',

	GET_PRODUCT_CATEGORY_DATAILS: 'GET_PRODUCT_CATEGORY_DATAILS',
	GET_PRODUCT_CATEGORY_DATAILS_SUCCESS: 'GET_PRODUCT_CATEGORY_DATAILS_SUCCESS',

	GET_CONSTANT: 'GET_CONSTANT',
	GET_CONSTANT_SUCCESS: 'GET_CONSTANT_SUCCESS'
}