import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { Link } from 'react-router-dom';


class AllBooks extends React.Component {
   constructor(props) {
       super(props);
       this.state = {
        frameheight: ''
       }
   }
	componentDidMount() {
      this.setFrameHeight();
      var _this=this;
      $( window ).resize(function() {
        _this.setFrameHeight();
      });
   }
   setFrameHeight(){
      //var height=parseInt($(window).height())-100;
      //console.log('+++++');
      //var frame = document.getElementById("Iframe"); 
      //var height = frame.contentWindow.document.body.getAttribute('content-height'); 
     // this.setState({frameheight: height+'px'});
   }

   render() {
      return (
         <div>
            <Header/>
            {/*-- content section --*/}
            <div className="iframe-maindiv">
   			<iframe id="Iframe" src="https://dev.wishuponmystory.com:447/" width="100%" height="100%" scrolling="no" frameborder="0"  style={{overflow: 'hidden'}} >
            </iframe>
            </div>
            <Footer/>
         </div>
      );
   }
}

export default AllBooks;